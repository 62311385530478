<script lang="ts">
import { defineComponent } from 'vue'

import AlertNoResults from '~/vue/_molecules/alerts/AlertNoResults.vue'
import CardBlog from '~/vue/_molecules/cards/CardBlog.vue'
import CardJob from '~/vue/_molecules/cards/CardJob.vue'
import CardOffice from '~/vue/_molecules/cards/CardOffice.vue'
import FormOffice from '~/vue/_organisms/forms/FormOffice.vue'
import ViewJobs from '~/vue/_organisms/views/ViewJobs.vue'
import ConsentDeclaration from '~/vue/_molecules/consents/ConsentDeclaration.vue'
import CardBlogGrid from '../_molecules/cards/CardBlogGrid.vue'

export default defineComponent({
    components: {
        'alert--no-results': AlertNoResults,
        'card--blog': CardBlog,
        'card--blog-grid': CardBlogGrid,
        'card--job': CardJob,
        'card--office': CardOffice,
        'consent--declaration': ConsentDeclaration,
        'form--office': FormOffice,
        'view--jobs': ViewJobs,
    }
})
</script>
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VueClickAway from "vue3-click-away"
import { animateComponents, animateSplide } from '~/js/utils/animation.ts'
import App from '~/vue/apps/ContentBuilder.vue'

// Main app
const main = async () => {
    const pinia = createPinia()
    const app = createApp(App)

    app.use(pinia)
    app.use(VueClickAway)
    
    return app.mount('#vue-content-builder')
}

// Execute async function
main().then(() => {
    //animation
    animateComponents()
    animateSplide()
})

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR active')
    })
}
<script setup lang="ts">
import { ref, onMounted } from 'vue'

interface Props {
    cookiebotId: string
}

const props = defineProps<Props>()
const wrapper = ref(null)

onMounted(() => {
    const cookiebotScript = document.createElement("script")
    cookiebotScript.setAttribute(
        "src",
        `https://consent.cookiebot.com/${props.cookiebotId}/cd.js`
    )
    cookiebotScript.setAttribute(
        "data-culture",
        `nl`
    )

    if (wrapper.value) {
        wrapper.value.appendChild(cookiebotScript);
    }
})
</script>

<template>
    <div ref="wrapper"></div>
</template>
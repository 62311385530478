<script setup lang="ts">
import { ref } from 'vue'

import { getGlobalTranslation } from '~/js/composables/translations'

import { Location } from '~/js/interface/location'

import ButtonPrimary from '~/vue/_atoms/buttons/ButtonPrimary.vue'
import FilterLocation from '~/vue/_molecules/filters/FilterLocation.vue'

interface Props {
    url: string
    utilities?: string
}

const props = withDefaults(defineProps<Props>(), {
    utilities: ''
})

const city = ref('')
const location = ref('')

const onSearch = (evt) => {
    // window.location.href = `${props.url}?postCode=${search.value}`
    const form = evt.srcElement

    form.querySelectorAll('input').forEach(input => {
        if (input.value == '') {
            input.disabled = true
        }
    })
}

const updateLocation = (loc: Location|string) => {
    if (typeof loc !== 'string') {
        city.value = loc.city
        location.value = loc.coordinates.join(',')
    }
}
</script>

<template>
    <form 
        :class="utilities"
        :action="url"
        @submit="onSearch"
    >
        <div class="relative grow flex items-center space-x-2">
            <span class="text-purple-600 text-xl pointer-events-none absolute left-5 top-1/2 -translate-y-1/2">
                <i class="fa-regular fa-location-dot" aria-hidden="false"></i>
            </span>
            <label class="w-full">
                <span class="sr-only">Postcode of plaats</span>
                <!-- <input 
                    type="text" 
                    :class="[
                        'pl-9 pr-10 pt-3 pb-2 inline-flex items-center print:hidden',
                        'bg-white',
                        'border border-purple-600',
                        'text-black font-primary text-sm md:text-base',
                        'focus:ring-4 focus:ring-blue-300 focus:outline-none',
                        'w-full'
                    ]"
                    placeholder="postcode of plaats"
                    v-model="search"
                > -->
                <input type="hidden" name="city" v-model="city" />
                <input type="hidden" name="location" v-model="location" />
                <FilterLocation 
                    :label="getGlobalTranslation('Locatie')"
                    :placeholder="getGlobalTranslation('Plaatsnaam of postcode')"
                    utilities="!max-w-full"
                    icon="far fa-location-dot"
                    type="locationQuery"
                    @setQuery="updateLocation($event)"
                />
            </label>

            <ButtonPrimary
                type="button"
                :content="getGlobalTranslation('Zoek')"
                utilities="focus:ring-purple-600"
                :ga="{
                    category: 'zoek-kantoor',
                    action: 'search',
                    label: 'zoek-kantoor',
                }"
            />
        </div>
    </form>
</template>